<template lang="pug">
.wizard-layout(:class="classes")
  v-slide-y-transition(leave-absolute)
    .wizard-layout-error(v-if="error")
      slot(name="error" :errorMsg="error")

  .wizard-layout__header(v-if="$slots.header")
    slot(name="header")
    .wizard-layout-navigator.mt-2
      slot(name="navigator")
    .wizard-layout-progress
      slot(name="progress")

  //- контент визарда
  .wizard-layout__content
    template(v-if="busy")
      .wizard__loader
        slot(name="busy" :msg="busyMsg")
          ui-loader(inline :text="busyMsg")

    .wizard-layout-card(:class="[{ 'wizard-card--loading': busy }]" v-show="!busy")
      slot
</template>

<script>
  import { UiLoader } from '@/uikit';

  export default {
    components: { UiLoader },

    props: {
      disabled: { type: Boolean, default: false },
      busy: { type: Boolean, default: false },
      busyMsg: { type: String, default: undefined },
      error: { type: String, default: undefined },
    },

    computed: {
      classes() {
        return [{ 'wizard-layout--busy': this.loading }];
      },
    },
  };
</script>

<style lang="scss">
  .wizard-layout {
    position: relative;
    .wizard-layout__header {
      background-color: c('gray5');
      padding: 24px 16px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .wizard-layout-navigator {
    }
    .wizard-layout-progress {
    }
  }

  .wizard-layout-error {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
</style>
