<template lang="pug">
.wizard-progress-linear-linear
  ui-linear-progress.c-primary(:value="value" bg-color="white")
</template>

<script>
  import { UiLinearProgress } from '@/uikit';

  export default {
    components: { UiLinearProgress },
    props: {
      value: { type: Number, default: 0 },
    },
  };
</script>
