<template lang="pug">
.wizard
  slot(
    v-bind="wizard"
    :onError="updateError"
    :transitAction="transitAction"
    :transitionAllowed="transitionAllowed"
  )
</template>

<script>
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('Wizard', 'brown');

  export function okResult(msg = undefined) {
    return {
      ok: true,
      msg,
    };
  }

  export function failedResult(msg = undefined) {
    return {
      ok: false,
      msg,
    };
  }

  export function createWizard(stepsArr) {
    return new WizardController(stepsArr);
  }

  export class Step {
    constructor(step) {
      this.name = step.name;
      this.title = step.title;
      this.index = step.index;
      this.number = step.number;
      this.url = step.url;
      this.progress = step.progress;
      this.transits = step.transits;
    }
  }

  export class WizardController {
    constructor(stepsArr) {
      this.steps = stepsArr.map(
        (step, index) => new Step({ ...step, index: index, number: index + 1 })
      );
    }

    getStep(stepName) {
      return this.steps.find((s) => s.name === stepName);
    }
  }

  export default {
    name: 'wizard',

    provide() {
      return {
        currentStep: this.currentStep,
        wizardSteps: this.wizardSteps,
        updateError: this.updateError,
        updateProgress: this.updateProgress,
        transitionAllowed: this.transitionAllowed,
        transitAction: this.transitAction,
        setBusy: this.setBusy,
        goNext: this.goNext,
        goPrev: this.goPrev,
      };
    },

    props: {
      controller: { type: Object, default: undefined },
      step: { type: Object, default: undefined },
      assetId: { type: String, default: undefined },
    },

    data() {
      return {
        wizardSteps: this.steps,
        currentStep: undefined,
        busyMsg: undefined,
        progress: undefined,
        firstErrorStepIndex: undefined,
        percentage: undefined,
        dirtyData: false,
        error: undefined,
        errorTimer: undefined,
        prevStep: undefined,
        nextStep: undefined,
      };
    },

    computed: {
      wizard() {
        return {
          currentStep: this.currentStep,
          error: this.error,
          busy: !!this.busyMsg,
          busyMsg: this.busyMsg,
          percentage: this.percentage,
          steps: this.wizardSteps,
          navItems: this.wizardStepItems,
          currentStepNumber: this.currentStep ? this.currentStep.number : undefined,
          totalStepsNumber: this.wizardSteps ? this.wizardSteps.length : undefined,
        };
      },

      wizardStepItems() {
        if (!this.wizardSteps) return [];

        const steps = this.wizardSteps.map((step, stepIndex) => ({
          valid: true,
          checked: true,
          navigatable: true,
          title: step.title,
          index: step.index,
          number: step.number,
          name: step.name,
        }));
        return steps;
      },

      // TODO: пример реализации: простановка признаков валидация шагов, возможности переходов, завершенности шага
      // wizardStepItems_orig() {
      //   // статусов еще нет, возвращаем пока пустоты
      //   if (!this.progress) return this.wizardSteps;

      //   // начальное значение: если нет шагов с ошибкой или нам не хватает только шага публикации
      //   const lastStepIndex = this.wizardSteps.length - 1;
      //   let allStepsValid =
      //     this.firstErrorStepIndex === -1 || this.firstErrorStepIndex === lastStepIndex;

      //   logger.log(
      //     'Расчет навигации',
      //     `Последний индекс: ${lastStepIndex}`,
      //     `Начальное allStepsValid: ${allStepsValid}`
      //   );
      //   const steps = this.wizardSteps.map((step, stepIndex) => {
      //     // закидываем мета данные для шага
      //     const title = step.title;
      //     const name = step.name;
      //     const number = step.number;

      //     // это текущий шаг?
      //     const active = stepIndex === this.currentStep.index;

      //     // на шаге вносились изменения?
      //     const dirty = active && this.dirtyData;

      //     // на текущем шаге есть ошибка?
      //     let error = active && Boolean(this.error?.length > 0);

      //     // шаг показывается как завершенный
      //     let checked = false;

      //     // можно ли перейти на шаг?
      //     let navigatable = false;

      //     // в режиме создания актива
      //     if (this.intent === 'create') {
      //       // шаг завершен, если бекенд пометил его как завершенный
      //       checked = this.progress[stepIndex].done || stepIndex < this.firstErrorStepIndex;

      //       // двигаемся пошагово только вперёд через валидацию каждого шага
      //       // на текущий шаг перейти нельзя
      //       // но можно назад на завершенный
      //       navigatable = !active && checked;
      //     }

      //     // в режиме редактирования значит, что все шаги были уже завершены
      //     else if (this.intent === 'edit') {
      //       // все шаги завершены, до первого ошибочного
      //       checked = this.progress[stepIndex].done || stepIndex < this.firstErrorStepIndex;

      //       // подсвечиваем ошибкой первый невалидный шаг
      //       error = error || stepIndex === this.firstErrorStepIndex;

      //       // на текущий шаг перейти нельзя
      //       // можно назад на завершенный
      //       // а также сразу прыгнуть на шаг с ошибкой
      //       navigatable = (!active && checked) || stepIndex === this.firstErrorStepIndex;
      //     }

      //     // на шаг публикации можно перейти, если все шаги до него завершены
      //     if (stepIndex !== lastStepIndex) {
      //       allStepsValid = allStepsValid && checked;
      //     }
      //     navigatable = navigatable || (allStepsValid && stepIndex === lastStepIndex);
      //     logger.log(
      //       `Индекс шага ${stepIndex}: allStepsValid: ${allStepsValid} (done: ${checked})`
      //     );

      //     return { dirty, checked, error, name, title, number, navigatable };
      //   });

      //   return steps;
      // },
    },

    watch: {
      step: {
        handler(newStep) {
          if (newStep) this.updateCurrentStep(newStep);
        },
        immediate: true,
      },
    },

    mounted() {
      this.initializeSteps();
    },

    methods: {
      initializeSteps() {
        this.wizardSteps = this.controller.steps;
        logger.log('Инициализация шагов визарда', this.wizardSteps);
        this.updateCurrentStep(this.step);
      },

      setBusy(msg) {
        this.busyMsg = msg;
      },

      updateCurrentStep(step) {
        if (!step) return;
        logger.log('Устанавливаем текущий шаг', step);
        this.currentStep = step;
        this.prevStep = this.getPrevStep(step.index);
        this.nextStep = this.getNextStep(step.index);
        this.percentage = step.progress;
        this.error = undefined;
      },

      updateProgress(info) {
        logger.log('Обновление прогресса визарда', info);

        this.intent = info.action; // create / edit
        this.firstErrorStepIndex = info.error_step - 1 || -1; // индекс = номер шага - 1
        this.progress = this.wizardSteps.map((s) => {
          const current = info.step.number === s.number; // на беке это считается текущим шагом, которого мы достигли
          const error = info.error_step === s.number; // шаг на коротом рушится бекенд-валидация
          const done = info.step.done.includes(s.number);
          const undone = info.step.undone.includes(s.number);
          return { current: current, done: done, undone: undone, error: error };
        });

        this.percentage = info.percentage;
      },

      getPrevStep(curStepIndex) {
        let newIndex = curStepIndex - 1;
        return newIndex >= 0 ? this.wizardSteps[newIndex] : undefined;
      },

      getNextStep(curStepIndex) {
        let newIndex = curStepIndex + 1;
        return newIndex <= this.wizardSteps.length ? this.wizardSteps[newIndex] : undefined;
      },

      getStep(stepIndex) {
        if (stepIndex < 0 || stepIndex > this.wizardSteps.length) return;
      },

      markDirty(value) {
        this.dirtyData = value;
      },

      async transitionAllowed(transitName, payload, options = {}) {
        // есть охранное условие перед переходом?
        let guardResult = undefined;
        let guard =
          options.guards && options.guards[transitName] ? options.guards[transitName] : undefined;
        logger.log('Есть охранка?', options?.guards, transitName);

        if (guard) {
          logger.log('Есть охранка:', transitName, guard);
          try {
            guardResult = await guard(payload);
          } catch (error) {
            guardResult = failedResult();
            this.$emit('guard:error', transitName, guardResult);
            logger.error(error);
          }
        }
        return guardResult;
      },

      async transitAction(transitName, payload, options = {}) {
        this.updateError(undefined);
        // есть действие при переходе?
        let actionResult = undefined;
        let action =
          options.actions && options.actions[transitName]
            ? options.actions[transitName]
            : undefined;
        if (action) {
          try {
            this.setBusy('Сохранение');
            logger.log('Выполняем транзитное действие...');
            actionResult = await action(payload);
          } catch (error) {
            logger.error(error);
          } finally {
            this.setBusy(undefined);
          }
          if (actionResult && !actionResult.ok) return actionResult;
        }

        let step = undefined;
        switch (transitName) {
          case 'next':
            logger.log('Транзит на следующий шаг', transitName, step);
            this.goToStep(this.nextStep);
            return;

          case 'prev':
            logger.log('Транзит на предыдущий шаг', transitName, step);
            this.goToStep(this.prevStep);
            return;

          case 'finish':
            logger.log('Транзит на завершение', transitName, step);
            this.$emit('finish');
            break;

          default:
            step = this.controller.getStep(transitName);
            logger.log('Транзит на произвольный шаг', transitName, step);
            this.goToStep(this.prevStep);
        }
      },

      goToStep(step) {
        logger.log('Переход на шаг', step.name);
        if (!step) {
          logger.error('Такого шага не существует. Расходимся');
          return false;
        }

        if (step.index === this.currentStep.index) {
          logger.error('Переход на текущий шаг. Расходимся');
          return false;
        }

        if (this.busy) {
          logger.error('Уже идет сохранение. Расходимся');
          return false;
        }

        logger.log('Эмитим событие перехода в родителя', step.name);
        this.$emit('transition', step);
      },

      // показать ошибку в визарде
      updateError(msg, options = { transitName: undefined, timeout: 4000 }) {
        this.error = msg;
        if (msg !== undefined) logger.log('ОШИБКА:', msg, options.timeout);
        else logger.log('Сброс сообщения об ошибке');

        if (this.errorTimer) clearTimeout(this.errorTimer);
        if (options.timeout > 0) {
          const timer = setTimeout(() => {
            this.error = undefined;
            clearTimeout(timer);
          }, options.timeout);
          this.errorTimer = timer;
        }
      },
    },
  };
</script>
