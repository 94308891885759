<template lang="pug">
.wizard-step
  .wizard-step__content
    slot(:ingresData="ingresData" :initialData="initialData")
  .wizard-step__actions
    slot(name="actions" :enabled="enabled" :trigger="trigger")
</template>

<script>
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('WizardStep', 'orange');

  export default {
    name: 'wizard-step',
    inject: ['transitionAllowed', 'transitAction', 'setBusy', 'updateError'],

    props: {
      step: { type: Object, default: undefined },
      transits: { type: Array, default: undefined },
      guards: { type: Object, default: () => {} },
      actions: { type: Object, default: () => {} },
      active: { type: Boolean, default: false },
      data: { type: [Object, Promise], default: undefined },
    },

    data() {
      return {
        initialData: undefined,
        formData: undefined,
        isDirty: false,
        hasError: false,
        errors: {},
      };
    },

    computed: {
      enabled() {
        if (!this.transits && Array.isArray(this.transits)) return {};

        const enabled = {};
        this.transits.forEach((stepName) => {
          enabled[stepName] = true;
        });
        return enabled;
      },
    },

    mounted() {
      this.initialize();
    },

    methods: {
      initialize() {
        if (this.data) {
          this.initialData = undefined;
          this.formData = undefined;
          try {
            logger.log('Инициализация шага');
            this.setBusy('Загрузка шага');
            Promise.resolve(this.data).then((initialData) => {
              logger.log('Резолв начальных данных шага', initialData);
              this.initialData = initialData;
              this.formData = initialData;
            });
          } catch (error) {
            this.updateError('Ошибка при загрузке шага');
            logger.error('Ошибка загрузки шага', error);
          } finally {
            this.setBusy(undefined);
          }
        }
      },

      ingresData(data) {
        logger.log('Пришли входные данные от форм', data);
        this.formData = Object.assign({}, this.formData, data);
      },

      async trigger(transitName) {
        const guardResult = await this.transitionAllowed(transitName, this.formData, {
          guards: this.guards,
        });

        if (guardResult && !guardResult.ok) {
          this.$emit('guard:error', guardResult.msg);
          this.updateError(guardResult.msg);
          return;
        }

        const actionResult = await this.transitAction(transitName, this.formData, {
          actions: this.actions,
        });

        if (actionResult && !actionResult.ok) {
          this.$emit('transition:error', actionResult.msg);
          this.updateError(actionResult.msg);
        }
      },
    },
  };
</script>

<style lang="scss">
  .wizard-step {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 16px;
  }

  .wizard-step__actions {
    margin-top: 40px;
  }
</style>
