<template lang="pug">
.asset-card(:class="classes")
  .asset-card__header(v-if="$slots.header")
    slot(name="header")

  .asset-card__content-wrapper(:style="styles")
    .asset-card__photos
      component(v-bind="tagAttrs" :is="tag")
        asset-photo(:photos="asset.photos")

    .asset-card__content
      .asset-card__asset-info
        .d-flex.justify-space-between.mb-2
          .asset__id
            asset-id(
              @notify:error="$emit('notify:error', $event)"
              @notify:success="$emit('notify:success', $event)"
            ) {{ asset.short_id }}
          template(v-if="asset.government")
            ui-icon.ml-auto.mr-1(
              name="eagle-symbol--colored"
              size=20
              v-tooltip.top="{ ref: 'GovernmentTooltip' }"
            )
            span(ref="GovernmentTooltip") Продажа государственного и&nbsp;муниципального&nbsp;имущества
          .asset__seller(v-if="asset.seller")
            .asset-seller(v-if="asset.seller.length < 20") {{ asset.seller }}
            .asset-seller(v-else v-tooltip="{ content: asset.seller }")
              .text--ellipsis.text--nowrap {{ asset.seller }}

        .t-caption-xsmall.c-gray80.mb-2 {{ asset.kind_name }}
        component.asset-title.link.link--inline-block(v-bind="tagAttrs" :is="tag")
          | {{ asset.title }}

      .asset-card__asset-address
        asset-address(v-if="asset.address") {{ asset.address }}

    .asset-card__extension
      slot(name="extension")
        .d-flex.align-center.justify-space-between.mb-3
          asset-deal-type-badge(v-if="asset.sale_type" :type="asset.sale_type")
          .asset-card__pledge-status.d-flex.align-center(
            v-if="asset.pledge_status_text === 'В залоге'"
          )
            ui-icon.c-gray70.mr-1(name="lock" size=16)
            .t-small.c-gray80 {{ asset.pledge_status_text }}

        .asset-price-card
          .asset-price-card__price.asset-price.asset-price--medium {{ forRent ? asset.rent_price : asset.price }}

    .asset-card__main-badges(v-if="mainBadges")
      asset-badge.mr-1(
        v-for="(badge, index) in mainBadges"
        :key="asset.short_id + '-' + index"
        large
        :kind="badge.kind"
      ) {{ badge.name }}

    .asset-card__other-badges(v-if="otherBadges")
      asset-badge.mb-1(
        v-for="(badge, index) in otherBadges"
        :key="asset.short_id + '-' + index"
        large
        :kind="badge.kind"
      ) {{ badge.name }}

    .asset-card__favorite(v-if="canToggleFavorite")
      asset-favorite-button.px-1(
        v-model="asset.favorite"
        no-text
        overlay
        preset="overlay"
        :asset-id="asset.short_id"
        :authorized-to-toggle="asset.authorized_to_toggle_favorite"
        width=40
      )

  .asset-card__footer(v-if="$slots.footer")
    slot(name="footer")
</template>

<script>
  import { UiIcon } from '@/uikit';
  import AssetFavoriteButton from '@/components/asset/AssetFavoriteButton.vue';
  import AssetPhoto from '@/components/asset/AssetPhoto.vue';
  import AssetId from '@/components/asset/AssetId.vue';
  import AssetAddress from '@/components/asset/AssetAddress.vue';
  import AssetBadge from '@/components/asset/AssetBadge.vue';
  import AssetDealTypeBadge from '@/components/asset/AssetDealTypeBadge.vue';

  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    name: 'asset-card',
    components: {
      UiIcon,
      AssetFavoriteButton,
      AssetPhoto,
      AssetId,
      AssetBadge,
      AssetAddress,
      AssetDealTypeBadge,
    },
    mixins: [measurable],

    props: {
      asset: { type: Object, required: true },
      newWindow: { type: Boolean, default: false },
      noElevateOnHover: { type: Boolean, default: false },
    },

    computed: {
      forRent() {
        return this.asset.sale_type === 'rent';
      },

      canToggleFavorite() {
        return this.asset.can_toggle_favorite;
      },

      classes() {
        return {
          'card-sheet--elevate-on-hover': !this.noElevateOnHover,
          ...this.sizeableClasses,
        };
      },

      styles() {
        return {
          ...this.measurableStyles,
        };
      },

      mainBadges() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['my'].includes(badge.kind))
          : undefined;
      },

      otherBadges() {
        return this.asset.badges
          ? this.asset.badges.filter((badge) => ['new', 'business'].includes(badge.kind))
          : undefined;
      },

      assetUrl() {
        let url = this.$path.asset(this.asset.short_id);
        if (this.newWindow) url = url + '?no_return=true';
        return url;
      },

      tag() {
        return this.assetUrl ? 'router-link' : 'div';
      },

      tagAttrs() {
        return this.assetUrl
          ? {
              to: this.assetUrl,
              target: this.newWindow ? '_blank' : false,
            }
          : undefined;
      },

      currentUser() {
        return this.$store.getters['currentUser'];
      },
    },
  };
</script>

<style lang="scss">
  .asset-card {
    background-color: c('white', 'light');
    box-shadow: shadow('xsmall', 'light');
    border-radius: 12px;
    overflow: hidden;

    .asset-card__content-wrapper {
      position: relative;
    }

    .asset-card__photos {
      border-radius: 4px;
    }

    .asset-card__content {
      display: flex;
      flex-direction: column;

      .asset-card__asset-info {
        flex: 1 0;
        padding: 16px;
      }

      .asset-card__asset-address {
        flex: 0 1;
        padding: 0 16px 16px 16px;
      }

      .asset-card__asset-props {
        display: none;
      }

      .asset-title {
        max-width: 375px;
      }

      .asset__props-list {
        .asset-prop {
          @include text--small;

          &__name {
            width: 220px;
            padding-bottom: 4px;
            color: c('black70', 'light');
            vertical-align: top;
          }

          &__value {
            padding-bottom: 4px;
            padding-left: 12px;
            color: c('black80', 'light');
          }
        }
      }
    }

    .asset-card__extension {
      border-top: 1px solid c('black10', 'light');
      padding: 16px 0 32px;
      margin: 0 16px;

      .asset-price-card {
        display: flex;
      }
    }

    .asset-card__main-badges {
      position: absolute;
      top: 144px;
      left: 16px;
    }

    .asset-card__other-badges {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .asset-card__favorite {
      position: absolute;
      top: 8px;
      right: 8px;

      .asset-favorite-button.btn-overlay.v-btn.v-btn--has-bg.theme--light {
        border-radius: 50%;
        height: 40px !important;
        background-color: rgba(37, 40, 43, 0.24) !important;

        &.asset-favorite-button--active {
          background-color: c('primary60', 'light') !important;
        }
        & .icon {
          background-color: white !important;
        }
      }
    }
  }
</style>
