<template lang="pug">
.wizard-nav-item(:class="classes" @click="onClick")
  .wizard-nav-item__img
    .wizard-nav-item__icon(v-if="error")
      ui-icon.c-red(name="error")
    template(v-else)
      .wizard-nav-item__icon(v-if="dirty")
        ui-icon.c-orange60(name="check-round-full")
      .wizard-nav-item__icon(v-else-if="checked")
        ui-icon.c-primary40(name="check-round-full")
      .wizard-nav-item__num(v-else)
        | {{ number }}
  .wizard-nav-item__title
    | {{ title }}
    //- ui-icon.c-orange80(v-if="dirty" name="pencil" :size="16")
</template>

<script>
  import { UiIcon } from '@/uikit';

  export default {
    components: { UiIcon },

    props: {
      number: { type: Number, default: undefined },
      name: { type: String, default: undefined },
      title: { type: String, default: undefined },
      active: { type: Boolean, default: false },
      dirty: { type: Boolean, default: false },
      checked: { type: Boolean, default: false },
      error: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      navigatable: { type: Boolean, default: false },
    },

    computed: {
      classes() {
        return [
          { 'wizard-nav-item--checked': this.checked },
          { 'wizard-nav-item--error': this.error },
          { 'wizard-nav-item--active': this.active },
          { 'wizard-nav-item--disabled': this.disabled },
          { 'wizard-nav-item--navigatable': !this.disabled && this.navigatable },
        ];
      },
    },

    methods: {
      onClick() {
        // if (!this.disabled && this.navigatable) this.$emit('click');
        this.$emit('click');
      },
    },
  };
</script>

<style lang="scss">
  .wizard-nav-item {
    display: flex;
    position: relative;
    padding-bottom: 12px;
    color: c('gray70');
    text-decoration: none;
    cursor: default;

    /* линия под шагом ========================================================== */
    &::after {
      position: absolute;
      content: '';
      top: 24px;
      left: 12px;
      width: 1px;
      bottom: 0;
      background-color: c('gray40');
    }

    &.wizard-nav-item--checked::after {
      background-color: c('primary60');
    }

    &.wizard-nav-item--error::after {
      background-color: c('red60');
    }

    &:last-child::after {
      display: none;
      padding: none;
    }
  }

  .wizard-nav-item--disabled {
    opacity: 0.5;
  }

  .wizard-nav-item--navigatable {
    cursor: pointer;
  }

  .wizard-nav-item__title {
    display: flex;
    align-items: center;
    @include font-size(14, 20, 400);
    color: c('gray70');
    padding-top: 2px;
    padding-left: 8px;

    .wizard-nav-item:hover {
      color: c('primary60');
    }

    .wizard-nav-item--active & {
      font-weight: 500;
      color: c('gray100');
    }

    .wizard-nav-item--navigatable & {
      color: c('gray80');
    }
    .wizard-nav-item--navigatable:hover & {
      color: c('primary60');
    }
  }

  .wizard-nav-item__img {
    width: 24px;
    height: 24px;
  }

  .wizard-nav-item__num {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    width: 24px;
    height: 24px;
    border: 2px solid c('gray5');
    border-radius: 50%;
    background: c('gray40');
    color: c('white');
    font-size: 12px;
    user-select: none;

    .wizard-nav-item--checked & {
      background-color: c('primary60');
      color: c('white');
    }
  }
</style>
