<template lang="pug">
.wizard-step-sequencer
  template(v-for="stepSlot in stepSlots")
    .wizard-step-sequencer__step(
      :class="`wizard-step-sequencer-${stepSlot.name}`"
      :key="stepSlot.name"
      v-if="activeOnly && stepSlot.active"
    )
      slot(:active="stepSlot.active" :name="stepSlot.name" :step="stepSlot.step")
</template>

<script>
  export default {
    props: {
      steps: { type: Array, default: () => [] },
      activeOnly: { type: Boolean, default: false },
      currentStep: { type: Object, default: undefined },
    },

    computed: {
      stepSlots() {
        if (this.currentStep) {
          return this.steps.map((step) => ({
            step: step,
            name: step.name,
            active: Boolean(step.name === this.currentStep.name),
          }));
        } else {
          return [];
        }
      },
    },
  };
</script>
