import { logger } from '@/uikit/util/logger';

export default {
  methods: {
    registerStoreModule(moduleName, storeModule) {
      const store = this.$store;

      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
        logger.log(`VUEX: регистрируем модуль: ${moduleName}`);
      } else {
        // переиспользуем существующий модуль
        logger.log(`VUEX: переиспользуем существующий модуль: ${moduleName}`);
      }
    },
  },
};
