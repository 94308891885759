<template lang="pug">
.wizard-navigator
  template(v-for="(item, index) in items")
    slot(name="item" :index="index" :item="item")
      wizard-nav-item(
        :key="item.name"
        v-bind="item"
        :active="!disabled && currentStep && currentStep.name === item.name"
        @click="onClick(item)"
        :disabled="disabled"
      )
</template>

<script>
  import WizardNavItem from './WizardNavItem.vue';
  const logger = createLogger('WizardNavigator', 'teal');
  import { createLogger } from '@/uikit/util';

  export default {
    components: { WizardNavItem },

    props: {
      currentStep: { type: Object, default: () => {} },
      disabled: { type: Boolean, default: false },
      items: { type: Array, default: () => [] },
    },

    mounted() {
      logger.log();
    },

    methods: {
      onClick(item) {
        this.$emit('click:item', item.name);
      },
    },
  };
</script>
